dmx.Component('calendar-source-google', {

  extends: 'calendar-source-base',

  attributes: {
    googleCalendarApiKey: {
      type: String,
      default: undefined,
    },

    googleCalendarId: {
      type: String,
      default: undefined,
    },
  },

  events: {
    success: Event,
    failure: Event,
  },

  render (node) {
    this.$parse();

    this._calendar.addEventSource({
      id: this.name,
      success: this.dispatchEvent.bind(this, 'success'),
      failure: this.dispatchEvent.bind(this, 'failure'),
      ...this._baseProps(),
      ...this.props.config,
    });
  },

  destroy () {
    this._calendar.getEventSourceById(this.name).remove();
  },

});