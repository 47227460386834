dmx.Component('calendar-source', {

  extends: 'calendar-source-base',

  attributes: {
    events: {
      type: Array,
      default: [],
    },

    eventId: {
      type: String,
      default: 'id',
    },

    eventTitle: {
      type: String,
      default: 'title',
    },

    eventUrl: {
      type: String,
      default: 'url',
    },

    eventStart: {
      type: String,
      default: 'start',
    },

    eventEnd: {
      type: String,
      default: 'end',
    },

    eventAllDay: {
      type: String,
      default: 'allDay',
    },

    eventDaysOfWeek: {
      type: String,
      default: 'daysOfWeek',
    },

    eventStartTime: {
      type: String,
      default: 'startTime',
    },

    eventEndTime: {
      type: String,
      default: 'endTime',
    },

    eventStartRecur: {
      type: String,
      default: 'startRecur',
    },

    eventEndRecur: {
      type: String,
      default: 'endRecur',
    },

    eventGroupId: {
      type: String,
      default: 'groupId',
    },

    eventDisplay: {
      type: String,
      default: 'display',
    },

    eventColor: {
      type: String,
      default: 'color',
    },

    eventBackgroundColor: {
      type: String,
      default: 'backgroundColor',
    },

    eventBorderColor: {
      type: String,
      default: 'borderColor',
    },

    eventTextColor: {
      type: String,
      default: 'textColor',
    },

    eventClassName: {
      type: String,
      default: 'className',
    },

    eventEditable: {
      type: String,
      default: 'editable',
    },

    eventOverlap: {
      type: String,
      default: 'overlap',
    },

    eventConstraint: {
      type: [String, Object],
      default: 'constraint',
    },

    eventExtendedProps: {
      type: String,
      default: '$value',
    },
  },

  render (node) {
    this.$parse();

    this._calendar.addEventSource({
      id: this.name,
      events: this._parseEvents.bind(this),
      ...this._baseProps(),
      ...this.props.config,
    });
  },

  performUpdate (updatedProps) {
    this._refetch();
  },

  destroy () {
    this._calendar.getEventSourceById(this.name).remove();
  },

  _parseEvents (info, success, failure) {
    this._events = [];

    if (Array.isArray(this.props.events)) {
      dmx.repeatItems(this.props.events).forEach(data => {
        const event = {}, scope = dmx.DataScope(data, this);

        for (const prop in this.props) {
          if (prop == 'events' || !prop.startsWith('event')) continue;
          const value = dmx.parse(this.props[prop], scope);
          if (value != null) {
            event[prop.slice(5, 6).toLowerCase() + prop.slice(6)] = value;
          }
        }

        this._events.push(event);
      });
    }

    this.children.forEach(child => {
      if (child instanceof dmx.Component('calendar-event')) {
        const event = { id: child.name };

        for (const prop in child.props) {
          if (!child.props.hasOwnProperty(prop)) continue;
          if (prop != 'config' && child.props[prop] != null) {
            event[prop] = child.props[prop];
          }
        }

        this._events.push({ ...event, ...child.props.config });
      }
    });

    success(this._events);

    this.set('events', this._events);
  },

});