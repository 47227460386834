dmx.Component('calendar-source-base', {

  initialData: {
    id: null,
    events: [],
  },
  
  attributes: {
    color: {
      type: String,
      default: undefined,
    },

    backgroundColor: {
      type: String,
      default: undefined,
    },

    borderColor: {
      type: String,
      default: undefined,
    },

    textColor: {
      type: String,
      default: undefined,
    },

    className: {
      type: String,
      default: undefined,
    },

    editable: {
      type: Boolean,
      default: undefined,
    },

    overlap: {
      type: Boolean,
      default: undefined,
    },

    constraint: {
      type: [String, Object],
      default: undefined,
    },

    display: {
      type: String,
      default: undefined,
    },

    config: {
      type: Object,
      default: {},
    },
  },

  methods: {
    refetch () {
      this._refetch();
    },
  },

  events: {
    eventclick: MouseEvent,
    eventmouseenter: MouseEvent,
    eventmouseleave: MouseEvent,
    eventdrop: Event,
    eventresize: Event,
  },

  init () {
    this._calendar = this.parent._calendar;
    this.set('id', this.name);
  },

  _baseProps () {
    const props = {};

    if (this.props.color != null) props.color = this.props.color;
    if (this.props.backgroundColor != null) props.backgroundColor = this.props.backgroundColor;
    if (this.props.borderColor != null) props.borderColor = this.props.borderColor;
    if (this.props.textColor != null) props.textColor = this.props.textColor;
    if (this.props.className != null) props.className = this.props.className;
    if (this.props.editable != null) props.editable = this.props.editable;
    if (this.props.overlap != null) props.overlap = this.props.overlap;
    if (this.props.constraint != null) props.constraint = this.props.constraint;
    if (this.props.display != null) props.display = this.props.display;

    return props;
  },

  _refetch () {
    this._calendar.getEventSourceById(this.name).refetch();
  },

});