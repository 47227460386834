dmx.Component('calendar-event', {

  attributes: {
    title: {
      type: String,
      default: undefined,
    },

    url: {
      type: String,
      default: undefined,
    },

    start: {
      type: String,
      default: undefined,
    },

    end: {
      type: String,
      default: undefined,
    },

    allDay: {
      type: Boolean,
      default: false,
    },

    daysOfWeek: { // For defining a simple recurring event
      type: Array,
      default: undefined,
    },

    startTime: { // For defining a simple recurring event
      type: String,
      default: undefined,
    },

    endTime: { // For defining a simple recurring event
      type: String,
      default: undefined,
    },

    startRecur: { // For defining a simple recurring event
      type: String,
      default: undefined,
    },

    endRecur: { // For defining a simple recurring event
      type: String,
      default: undefined,
    },

    groupId: {
      type: String,
      default: undefined,
    },

    display: {
      type: String,
      default: undefined,
    },

    editable: {
      type: Boolean,
      default: undefined,
    },

    color: {
      type: String,
      default: undefined,
    },

    backgroundColor: {
      type: String,
      default: undefined,
    },

    borderColor: {
      type: String,
      default: undefined,
    },

    textColor: {
      type: String,
      default: undefined,
    },

    className: {
      type: String,
      default: undefined,
    },

    constraint: {
      type: [String, Object],
      default: undefined,
    },

    overlap: {
      type: Boolean,
      default: undefined,
    },

    extendedProps: {
      type: Object,
      default: undefined,
    },

    config: {
      type: Object,
      default: {},
    },
  },

  performUpdate (updatedProps) {
    if (this.parent._refetch) {
      this.parent._refetch();
    }
  },

  // TODO: deprecate this, use JSON or expression instead which is the App Connect 2 default way
  // we overwrite the default $parseAttributes method to parse some special non-standard attributes
  $parseAttributes (node) {
    dmx.BaseComponent.prototype.$parseAttributes.call(this, node);

    for (const attr of node.attributes) {
      // special handling for the constraint business hours attribute
      if (attr.name == 'constraint.business-hours') {
        // TODO: insteaf of `constraint.business-hours=""` it should be `constraint="businessHours"`
        this.props.constraint = 'businessHours';
      }

      // special handling for the constraint group attribute
      if (attr.name == 'constraint.group') {
        // TODO: insteaf of `constraint.group="groupId"` it should be `constraint="groupId"`
        this.props.constraint = attr.value;
      }
    }
  },

});